export default {
  en: {
    title: "About",
    description: `
    Science in Algeria, like many other sectors, has not been spared by the systematic abandonment throughout its value vector, from primary school to research centers, passing by middle and secondary school as well as university. A strong Algeria as any Algerian hopes must necessarily take its strength among other things from science. To build a system, we need several areas of expertise. To build a country, we need all the areas of expertise. That said, and given the fairly large gap between the current state of Algeria and the aspirations of Algerians, DZScience views science as a whole with a spirit of inclusion. The universality of science is at the university, so:
`
  },
  fr: {
    title: "À-Propos",
    description: `
    La science en Algérie, comme plusieurs autres secteurs d'ailleurs, n’a pas été épargnée par l’abandon systématique sur tout le long de son vecteur de valeur, du primaire aux centres de recherche, en passant par le moyen, le secondaire, le technique et l’universitaire. Une Algérie forte comme espère tout algérien doit obligatoirement prendre sa force entre autres de la science. Pour bâtir un système, il faut plusieurs expertises. Pour bâtir un pays, il faut toutes les expertises. Ceci dit, et compte tenu de l’écart assez important entre l’état actuel de l’Algérie et les aspirations des algériens, DZScience considère la science dans son universalité avec un esprit d’inclusion. L’universalité de la science est à l’université, alors:
    `
  }
};
