export default {
  en: {
    name: "Your name",
    title: "Get in touch with us!",
    email: "Your email",
    message: "Your message",
    action: "Send"
  },
  fr: {
    name: "Votre nom",
    title: "Prenez contact avec nous!",
    email: "Votre email",
    message: "Votre message",
    action: "Envoyer"
  }
};
