import React from "react";
import { ProjectItem } from "./ProjectItem";
import { useTranslation } from "react-i18next";

export function Jawdatna() {
  const { t } = useTranslation();
  return (
    <ProjectItem
      title={t("projects:jawdatna.title")}
      tabs={t("projects:jawdatna.tabs")}
      project={"jawdatna"}
    />
  );
}
