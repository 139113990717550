import React, { useState } from "react";
import { Tabs } from "antd";
import PropTypes from "prop-types";
import { InterestedButton } from "./InterestedButton";
import { func } from "prop-types";
const { TabPane } = Tabs;

export function ProjectItem({ title, tabs, project }) {
  const [active, setActive] = useState(0);
  const onChange = value => setActive(Number(value));

  return (
    <div className="project-item pt-4">
      <div className="row justify-content-center ">
        <div className="col-10">
          <div className="d-flex my-4">
            <h3 className="text-title">{title}</h3>
          </div>
          <div className="d-none d-md-block">
            <ResponsiveTabs
              tabPosition="left"
              onChange={onChange}
              tabs={tabs}
              active={active}
              project={project}
            />
          </div>
          <div className="d-md-none">
            <ResponsiveTabs
              tabPosition="top"
              onChange={onChange}
              tabs={tabs}
              active={active}
              project={project}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const ResponsiveTabs = ({ tabPosition, onChange, tabs, active, project }) => {
  return (
    <Tabs
      onChange={onChange}
      defaultActiveKey="0"
      tabPosition={tabPosition}
      className="text-center"
    >
      {tabs.map(({ content, title, link }, index) => (
        <TabPane tab={<div className="mx-5 ">{title}</div>} key={index}>
          <div className="text-left text-subtitle">
            {content}
            {link && (
              <a href={"https://" + link.href} className="ml-2" target="blank">
                {link.text}
              </a>
            )}
          </div>
          <div className={active === tabs.length - 1 ? "" : "d-none"}>
            <InterestedButton project={project} />
          </div>
        </TabPane>
      ))}
    </Tabs>
  );
};

ResponsiveTabs.propTypes = {
  onChange: func.isRequired,
  tabs: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
  project: PropTypes.string.isRequired,
  tabPosition: PropTypes.string.isRequired
};
ProjectItem.propTypes = {
  tabs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired
};
