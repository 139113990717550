import { SET_EMAIL } from "./action";

const initial = { email: localStorage.getItem("email") || "" };

export function settingsReducer(state = initial, { type, payload }) {
  switch (type) {
    case SET_EMAIL:
      return { ...state, email: payload.email };
    default:
      return state;
  }
}
