import axios from "axios";
import Swal from "sweetalert2";
import { config } from "../../../utils/constants";
import { setEmail } from "../settings/action";

export const BEGIN_REQUEST = "BEGIN_REQUEST";
export const SUCCESS_REQUEST = "SUCCESS_REQUEST";
export const FAIL_REQUEST = "FAIL_REQUEST";

export const beginRequest = module => ({
  type: BEGIN_REQUEST,
  payload: { module }
});

export const successRequest = (module, payload) => ({
  type: SUCCESS_REQUEST,
  payload: { module, ...payload }
});

export const failRequest = (module, payload) => ({
  type: FAIL_REQUEST,
  payload: { module, ...payload }
});

export const submitMessage = (params, t) => dispatch => {
  dispatch(beginRequest("contact"));

  axios
    .get(config.MESSAGES_END, { params })
    .then(({ data }) => {
      Swal.fire({
        title: t("common:messages.success.title"),
        text: t("common:messages.success.content"),
        icon: "success",
        showCloseButton: true
      });
      dispatch(successRequest("contact", { data }));
      dispatch(setEmail(params.email));
    })
    .catch(err => {
      Swal.fire({
        title: t("common:messages.fail.title"),
        text: t("common:messages.fail.content"),
        icon: "error",
        showCloseButton: true
      });

      dispatch(failRequest("contact", { err }));
    });
};

export const submitEmail = (params, t) => dispatch => {
  dispatch(beginRequest("interested"));

  axios
    .get(config.MEMBERS_END, { params })
    .then(({ data }) => {
      Swal.fire({
        title: t("common:interest.success.title"),
        text: t("common:interest.success.content"),
        icon: "success",
        showCloseButton: true
      });
      dispatch(successRequest("interested", { data }));
      dispatch(setEmail(params.email));
    })
    .catch(err => {
      Swal.fire({
        title: t("common:interest.fail.title"),
        text: t("common:interest.fail.content"),
        icon: "error",
        showCloseButton: true
      });

      dispatch(failRequest("interested", { err }));
    });
};
