import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';

export function BulletItem({ title, description }) {
  return (
    <div>
      <Timeline.Item className='m-0'>
        <div className='pb-4 pl-2'>
          <h3 className='m-0 title-text-primary'> {title}</h3>
          <span className='text-subtitle' style={{ fontSize: '15px' }}>
            {description}
          </span>
        </div>
      </Timeline.Item>
    </div>
  );
}

BulletItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};
