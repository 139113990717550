export default {
  en: {
    hint: "Leave your email , and we will reach you  with our latest news",
    emailPlaceholder: "Enter email address",
    description: `Science in Algeria, like many other sectors moreover, has not been spared by the systematic abandonment throughout its value vector, from primary to research centers, via the medium, the secondary, the technical and the academic`,
    copyright: ` ${new Date().getFullYear()} All rights reserved | This website is owned by`,
    by: "DzScience"
  },
  fr: {
    hint:
      "Laissez votre e-mail, et nous vous contacterons avec nos dernières nouvelles",
    emailPlaceholder: "Entrer votre adresse e-mail",
    description: `La science en Algérie, comme plusieurs autres secteurs d'ailleurs, n’a pas été épargnée par l’abandon systématique sur
    tout le long de son vecteur de valeur, du primaire aux centres
    de recherche, en passant par le moyen, le secondaire, le
    technique et l’universitaire`,
    copyright: ` ${new Date().getFullYear()} Tous droits réservés | Ce site Web appartient à`,
    by: "DzScience"
  }
};
