import React from "react";
import { useTranslation } from "react-i18next";

export function Hierarchy() {
  const { t } = useTranslation();
  return (
    <span className="ml-2 d-inline-block">
      {t("projects:dzscience.tabs.description.hierarchy").map(
        (hierarchy, index, array) => (
          <span
            key={index}
            className="hierarchy d-inline-flex rounded  align-items-center align-middle mr-1"
          >
            <span className="rounded mr-1 bg-light d-inline-block px-2">
              {hierarchy}
            </span>
            {array.length - 1 !== index && <span className="arrow">⇒</span>}
          </span>
        )
      )}
    </span>
  );
}
