export default {
  en: {
    home: "Home",
    about: "About",
    projects: "Projects",
    contact: "Contact"
  },
  fr: {
    home: "Acceuil",
    about: "À-Propos",
    projects: "Projets",
    contact: "Contact"
  }
};
