import React from "react";
import { ProjectItem } from "./ProjectItem";
import { useTranslation } from "react-i18next";

export function Bahith() {
  const { t } = useTranslation();
  return (
    <ProjectItem
      title={t("projects:bahith.title")}
      tabs={t("projects:bahith.tabs")}
      project={"bahith"}
    />
  );
}
