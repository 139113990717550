import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LanguagePicker } from "./LanguagePopup";
import { Link } from "react-scroll";
import "./style.scss";
import { Button, Icon } from "antd";

export function Header() {
  const { t } = useTranslation();
  const [isMenuHidden, setIsMenuHidden] = useState(true);

  const toggleMenu = useCallback(() => setIsMenuHidden(!isMenuHidden), [
    isMenuHidden
  ]);

  return (
    <header className="main-header">
      <div className="mobile-menu-container d-md-none pt-3 pb-1  px-3  position-relative">
        <div className="row no-gutters   align-items-center    ">
          <div className="col"></div>
          <Button
            color="#20ad92"
            onClick={toggleMenu}
            className="menu-btn btn btn-outline-success px-3 py-1"
          >
            <Icon
              type={isMenuHidden ? "menu" : "close"}
              color="#20ad92"
              className="menu-icon"
              style={{ fontSize: "25px" }}
            />
          </Button>
        </div>
        <div
          className={
            "mobile-menu rounded p-2 mx-3" +
            (isMenuHidden ? " mobile-menu-hidden " : "")
          }
        >
          <ul className="main-nav-list m-0 ">
            <li className="nav-header-item d-block  ">
              <Link
                onClick={toggleMenu}
                className="px-2 p-2 "
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {t("header:home")}
              </Link>
            </li>
            <li className="nav-header-item d-block ">
              <Link
                onClick={toggleMenu}
                className="px-2 p-2 "
                activeClass="active"
                to="about-us"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {t("header:about")}
              </Link>
            </li>
            <li className="nav-header-item d-block">
              <Link
                onClick={toggleMenu}
                className="px-2 p-2 "
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {t("header:projects")}
              </Link>
            </li>
            <li className="nav-header-item d-block">
              <Link
                onClick={toggleMenu}
                className="px-2 p-2 "
                activeClass="active"
                to="footer"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {t("header:contact")}
              </Link>
            </li>
            <li className="nav-header-item d-block ">
              <div className="px-2 p-2">
                <LanguagePicker />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <nav className="container d-none d-md-block bg-transparent pt-4">
        <ul className=" d-flex main-nav-list  justify-content-end   align-items-center">
          <li className="nav-header-item ">
            <Link
              className="px-2 p-1 "
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("header:home")}
            </Link>
          </li>
          <li className="nav-header-item ">
            <Link
              className="px-2 p-1 "
              activeClass="active"
              to="about-us"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("header:about")}
            </Link>
          </li>
          <li className="nav-header-item">
            <Link
              className="px-2 p-1 "
              activeClass="active"
              to="projects"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("header:projects")}
            </Link>
          </li>
          <li className="nav-header-item">
            <Link
              className="px-2 p-1 "
              activeClass="active"
              to="footer"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("header:contact")}
            </Link>
          </li>
          <li className="nav-header-item ml-4">
            <LanguagePicker />
          </li>
        </ul>
      </nav>
    </header>
  );
}
