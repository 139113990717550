import React from 'react';
import { useTranslation } from 'react-i18next';
import { BulletItem } from './BulletItem';
import { Timeline } from 'antd';
import './style.scss';

export function Bullets() {
  const { t } = useTranslation();

  return (
    <section className='content-bullets py-0'>
      <div className='content text-justify'>
        <div className='row'>
          <Timeline className='col-12 col-lg-6 mb-4 mb-lg-0'>
            {t('bullets:items.firsts').map((item, index) => (
              <BulletItem key={index} {...item} />
            ))}
          </Timeline>

          <div className='col-12 col-lg-6 text-center'>
            <img src='img/kids.svg' alt='' width='500' className='img-aside' />
          </div>
          <div className='col-12 m-4 '></div>
          <div className='col-12 col-lg-6 mb-4 mb-lg-0 text-center'>
            <img
              src='img/medcine.svg'
              alt=''
              width='500'
              className='img-aside'
            />
          </div>
          <Timeline className='col-12 col-lg-6 '>
            {t('bullets:items.seconds').map((item, index) => (
              <BulletItem key={index} {...item} />
            ))}
          </Timeline>
        </div>
      </div>
    </section>
  );
}
