const text = {
  en: {
    title: 'Projects',
    introduction: `Here is the list of our projects. You can have more information by clicking on each of them.`,
    note: `Note : all the these projects are under the DzScience , if you need any news about any project click on the intersted tab and leave your email`,
    interested: "Yes I'am interested",

    bahith: {
      title: 'Bahith - R&D Project Management and Administration Portal',
      logo: '/img/projects/bahith-logo-inline.svg',
      tabs: [
        {
          content: `Help Algerian researchers to control their R&D projects in order to optimize the achievement of convincing results. 
          `,
          title: 'Goal'
        },
        {
          title: 'Description',
          content: `Bahith helps researchers to properly describe their R&D projects. From the expertise of the community, it is possible for Bahith to guide the researcher in the description of the valorisations of his project, we cite publications, student training and product achievements. As a result, the description of the project becomes more realistic, and thus can actually serve as a project plan. In addition to achieving this objective, Bahith is also a web portal that allows you to manage and administer R&D projects.
`
        },
        {
          title: 'Advancement',
          content: `We are happy to deploy the first step of bahith, dedicated to gathering researchers expertise.`,
          link: {
            href: 'bahith.dzscience.com',
            text: 'Link Bahith'
          }
        },
        {
          title: 'Interested',
          content: `If you are interested in this project, leave us your contact and we will communicate with you on the progress of the project.`
        }
      ]
    },
    dzscience: {
      title: 'Portail DZScience ',
      logo: '/img/projects/dzscience-logo.svg',
      tabs: {
        goal: {
          content: `Support DZScience operations.`,
          title: 'Goal'
        },
        description: {
          title: 'Description',
          start: `DZScience operations will be supported by an operation and collaboration web portal. A whole system has already been thought, and is mainly organized around the representation of science according to the university structure represented by the hierarchy`,
          startEnd: `. Expertise is associated with time, namely how much time a scientist spends developing in a specialty, a field, etc.`,
          hierarchy: ['Faculty', 'Department', 'Domain', 'Specialty'],
          the: 'The',
          theEnd: `representation constitutes the ontological core of the representation of Science in DZScience. Among other things, it will allow us to:`,
          allow: [
            `Identify each scientific activity more precisely.`,
            `Facilitate the implicit grouping of our members by their scientific fields.`,
            `Facilitate the grouping of scientific knowledge as structured content.`,
            `Support other projects such as Bahith or the portal of professional orders.`
          ],
          end: `We will deploy the detailed description of this project later.`
        },
        advancement: {
          title: 'Advancement',
          content: `The current portal is the first deployment in this project. We are going forward.`
        },
        interested: {
          title: 'Interested',
          content: `If you are interested in this project, leave us your contact and we will communicate with you on the progress of the project.
          `,
          action: "Yes I'am interested"
        }
      }
    },
    jawdatna: {
      title: 'Management and administration of professional orders',
      logo: '/img/projects/mihnatek-logo.svg',
      tabs: [
        {
          content: `Develop a portal for the management and administration of professional orders in Algeria.`,
          title: 'Goal'
        },
        {
          title: 'Description',
          content: `In order to protect the well-being of citizens, a paying government has the duty to formally define the quality of a service expected from a professional towards the citizen. Professional orders are generally mandated by the government to manage and administer the professions for the welfare of citizens. We are in the sphere of professional inspection, which aims, among other things, to ensure the quality of the execution of a job, through systemic control.
          Despite the large number of professions and jobs, the administration and management of professional orders use the same main processes. The difference lies in the professional content of the profession, generally existing in the professional forms for admission and inspection of members.          
`
        },
        {
          title: 'Advancement',
          content: `A prototype already exists, built on the basis of the processes of certain professional orders in Quebec, Canada. ESI student projects are ready to start in September 2020. We will post the projects to students later. 
          `
        },
        {
          title: 'Interested',
          content: `If you are interested in this project, leave us your contact and we will communicate with you on the progress of the project.`
        }
      ]
    }
  },
  fr: {
    title: 'Projets',
    introduction: `Voici la liste de nos projets. Vous pouvez avoir plus d’informations en cliquant sur chacun d’eux.`,
    note: `Remarque: tous ces projets sont sous DzScience, si vous avez besoin de nouvelles sur un projet, cliquez sur l'onglet intersted et laissez votre email`,
    interested: 'Oui je suis intéressé',

    bahith: {
      title: 'Bahith - Portail de Gestion et Admnistration de Projets R&D',
      logo: '/img/projects/bahith-logo-inline.svg',
      image: '/img/projects/bahith-img.svg',
      tabs: [
        {
          content: `Aider les chercheurs algériens à contrôler leurs projets R&D afin d’optimiser l'atteinte de résultats probants. 
          `,
          title: 'Objectif'
        },
        {
          title: 'Description',
          content: `Bahith aide les chercheurs à bien décrire leurs projets R&D. À partir de l’expertise de la communauté, il est possible à Bahith de guider le chercheur dans la description des valorisations de son projet, nous citons les publications, les formations d’étudiants et les réalisations de produits. Comme résultat, la description du projet devient plus réaliste, et ainsi peut servir réellement comme un plan du projet. En plus de réaliser cet objectif, Bahith est aussi un portail web qui permet de gérer et administrer les projet R&D.
`
        },
        {
          title: 'Avancement',
          content: `Nous sommes heureux de déployer la première étape de bahith, consacrée à la collecte d’expertises.`,
          link: {
            href: 'bahith.dzscience.com',
            text: 'Lien Bahith'
          }
        },
        {
          title: 'Interesé',
          content: `Si vous êtes intéressé par ce projet, laissez-nous votre contact et nous communiquerons avec vous sur les avancements du projet`
        }
      ]
    },
    dzscience: {
      title: 'Portail DZScience',
      logo: '/img/projects/dzscience-logo.svg',
      tabs: {
        goal: {
          content: `Supporter les opérations de DZScience.`,
          title: 'Objectif'
        },
        description: {
          title: 'Description',
          start: `Les opérations de DZScience vont être supportées par un portail web d’opération et de collaboration. Tout un système a été déjà pensé et une partie conçu, et est principalement organisé autour de la représentation de la science suivant la structure universitaire représentée par la hiérarchie`,
          startEnd: `.L’expertise est associée au temps, à savoir combien de temps un scientifique passe à se développer dans une spécialité, un domaine, etc.`,
          hierarchy: ['Faculté', 'Département', 'Domaine', 'Spécialité'],
          the: 'The',
          theEnd: `nommée par la Structure Scientifique, constitue le noyau ontologique de la représentation de la Science dans DZScience. Elle va nous permettre entre autre de :`,
          allow: [
            `Identifier chaque activité scientifique d’une manière plus précise.`,
            `Faciliter le regroupement implicite de nos membres par leurs domaines scientifiques.`,
            `Faciliter le groupement des connaissances scientifiques comme contenu structuré.`,
            `Supporter les autres projets comme Bahith ou le portail des ordres professionnels.`
          ],
          end: `Nous déploierons plus tard la description détaillée de ce projet.`
        },
        advancement: {
          title: 'État d’avancement',
          content: `Le portail actuel en ai le premier déploiement dans ce projet. On avance.`
        },
        interested: {
          title: 'Intéressé',
          content: `Si vous êtes intéressé par ce projet, laissez-nous votre contact et nous communiquerons avec vous sur les avancements du projet.`,
          action: 'Oui je suis intéressé'
        }
      }
    },
    jawdatna: {
      title: 'Gestion et administration des ordres professionnels',
      logo: '/img/projects/mihnatek-logo.svg',
      tabs: [
        {
          content: `Développer un portail de gestion et d’administration des ordres professionnels en Algérie.`,
          title: 'Objectif'
        },
        {
          title: 'Description',
          content: `Dans le soucis de la protection du bien-être des citoyens, un gouvernement payeur a le devoir de définir formellement la qualité d’un service attendu d’un professionnel envers le citoyen. Les ordres professionnels sont généralement mandatés par le gouvernement pour gérer et administrer les professions pour le bien être du citoyen. Nous sommes dans la sphère de l’inspection professionnel, qui a comme but entre autre d’assurer la qualité de l’exécution d’un travail, par le biais du contrôle systémique.
          Malgré le nombre important des professions et métiers, l’administration et la gestion des ordres professionnels utilisent pratiquement les mêmes processus. La différence réside dans le contenu métier de la profession, généralement existants dans les formulaires métiers d’admission et d’inspection des membres.      `
        },
        {
          title: 'Avancement',
          content: `Un prototype existe déjà, bâtit à base des processus de certains ordres professionnels du Québec, Canada. Un projet pour étudiants de l’ESI est prêt pour démarrer en septembre 2020. Nous allons publier plus tard les projets aux étudiants. Nous encourageons. 
          `
        },
        {
          title: 'Intéressé',
          content: `Si vous êtes intéressé par ce projet, laissez-nous votre contact et nous communiquerons avec vous sur les avancements du projet.`
        }
      ]
    }
  }
};

export default text;
