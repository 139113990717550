import React from "react";
import { useTranslation } from "react-i18next";
import { Contact } from "../Contact/Contact";
import "./style.scss";
export function Footer() {
  const { t } = useTranslation();
  return (
    <footer id="footer">
      <div className=" position-relative footer_area p-0 m-0 ">
        <div className="overlay"></div>
        <div className="container h-100">
          <div className="row h-100 align-items-center   mx-auto">
            <aside className="col   ">
              <Contact />
            </aside>
          </div>
        </div>
      </div>
      <div className="p-3 text-center">
        <p className="p-0 m-0">
          Copyright &copy;{t("footer:copyright")}
          <a href="https://dzscience.com" target="blank" className="ml-2">
            {t("footer:by")}
          </a>
        </p>
      </div>
    </footer>
  );
}
