import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Spin } from "antd";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { submitEmail } from "../../store/reducers/Request/actions";
import { createSelector } from "reselect";
import { getEmail } from "../../store/reducers/settings/selector";

export function InterestedButton({ project }) {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);
  const [email, setEmail] = useState("");
  const { email: localEmail } = useSelector(getEmail);

  const { loading } = useSelector(
    createSelector(
      state => state.request.interested,
      interested => interested || {}
    )
  );
  const onChange = useCallback(({ target: { value } }) => setEmail(value), []);
  const dispatch = useDispatch();

  const onInterest = useCallback(() => {
    setHidden(false);
  }, []);

  const onSubmit = useCallback(
    event => {
      event.preventDefault();
      dispatch(submitEmail({ email, [project]: true }, t));
    },
    [email] // eslint-disable-line
  );

  useEffect(() => {
    setEmail(localEmail);
  }, [localEmail]); // eslint-disable-line

  return (
    <div className="d-flex m-1 mt-4 text-left email-interested">
      <button
        id="interested-btn"
        type="button"
        className="btn btn-outline-success interested-btn mr-3  "
        onClick={onInterest}
      >
        {t("projects:interested")}
      </button>
      <form
        onSubmit={onSubmit}
        className={
          "d-flex email-container " + (hidden ? "hidden-interested" : "")
        }
      >
        <input
          name="EMAIL"
          className="rounded-0 form-control h-100 email-interested  px-3 py-3 outline-none"
          placeholder="Enter email address"
          required
          value={email}
          onChange={onChange}
          type="email"
          disabled={loading}
        />
        <button
          type="submit"
          disabled={loading}
          className={"rounded-0   btn btn-outline-success  font-weight-bold "}
        >
          {loading ? (
            <Spin
              className="d-inline-flex align-items-center"
              indicator={
                <Icon type="loading" style={{ fontSize: 15, color: "black" }} />
              }
            />
          ) : (
            <Icon type="arrow-right" />
          )}
        </button>
      </form>
    </div>
  );
}

InterestedButton.propTypes = {
  project: PropTypes.string.isRequired
};
