import React from "react";
import { Tabs } from "antd";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Bahith } from "./Bahith";
import { DZScience } from "./DZScience";
import { Jawdatna } from "./Jawdatna";
const { TabPane } = Tabs;

export function Projects() {
  const { t } = useTranslation();

  return (
    <section id="projects" className="projects-section">
      <div className="container">
        <div className="text-center">
          <h2> {t("projects:title")}</h2>
          <img
            alt="title"
            className="title-container"
            src="img/title-container.svg"
          />
        </div>

        <div className="intro-projects text-center">
          <p className="text-subtitle">{t("projects:introduction")}</p>
        </div>
        <div>
          <Tabs className="text-center">
            <TabPane
              key="1"
              className="project-tab"
              tab={
                <img
                  alt="title"
                  src={t("projects:bahith.logo")}
                  className=" mx-md-3 project-img "
                />
              }
            >
              <Bahith />
            </TabPane>
            <TabPane
              key="2"
              className="project-tab"
              tab={
                <img
                  alt="title"
                  src={t("projects:dzscience.logo")}
                  className=" mx-md-3 project-img "
                />
              }
            >
              <DZScience />
            </TabPane>
            <TabPane
              key="3"
              className="project-tab"
              tab={
                <img
                  alt="title"
                  src={t("projects:jawdatna.logo")}
                  className=" mx-md-3 project-img "
                />
              }
            >
              <Jawdatna />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </section>
  );
}
