import React from "react";
import { Header } from "../Header/Header";
import { useTranslation } from "react-i18next";
import "./styles.scss";
export function HomeBanner() {
  const { t } = useTranslation();
  return (
    <section id="home" className=" bg-cover position-relative">
      <div className="position-absolute w-100 ">
        <Header />
      </div>
      <div className="w-100 banner-img">
        <img
          alt="welcome background"
          src="/img/banner/welcome-bg.svg"
          className="w-100"
        />
      </div>
      <div className="container banner-title-container ">
        <div className="row ">
          <div className="col-12 col-md-8 align-items-center">
            <h2 className="  home-bannerd-title title-text-primary">
              {t("home:title")}
            </h2>
            <p className="home-banner-description ">{t("home:presentation")}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
