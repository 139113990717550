import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bullets } from './Bullets/Bullets';

export function About() {
  const { t } = useTranslation();

  return (
    <div className='container mt-5'>
      <section id='about-us' className=' about-section '>
        <div className='text-center'>
          <h2>{t('about:title')}</h2>
          <img
            className='title-container'
            src='img/title-container.svg'
            alt=''
          />
        </div>
      </section>

      <section
        className='content-intro '
        style={{ marginBottom: '80px', marginTop: '30px' }}
      >
        <div className='content  container text-justify'>
          <p className='w-75mx-auto description-text'>
            {t('about:description')}
          </p>
        </div>
      </section>
      <Bullets />
    </div>
  );
}
