export default {
  en: {
    items: {
      firsts: [
        {
          title: `It is up to our scientists in faculties of Letters and Human Sciences`,
          description: `to review the services to our citizens and mainly the education of our children.`
        },
        {
          title: `It is up to our scientists in faculties of law`,
          description: ` to institute the regulations and policies enabling us to live together as citizens.`
        },
        {
          title: `It is up to our scientists in faculties of administration`,
          description: `to establish administrative rigor, with in the foreground the systematization of control structures, specially budget/spent control.`
        },
        {
          title: `It’s up to our engineering scientists`,
          description: `to rebuild the infrastructure for producing goods.`
        }
      ],
      seconds: [
        {
          title: `It’s up to our scientists in faculties of sport`,
          description: `to train our next Olympic champions.`
        },
        {
          title: `It's up to our scientists in the faculties of the arts`,
          description: `to revive our multidimensional culture.`
        },
        {
          title: `It’s up to our scientists in faculties of medicine`,
          description: `to ensure that our next presidents are treated in our hospitals.`
        },
        {
          title: `It is up to our scientists in faculties of science`,
          description: `to bring us to Algeria 2.0`
        },
        {
          title: `Without forgetting all the other scientists, technicians, engineers, researchers, professors, without any exclusions,`,
          description: `all  must bring to a strong Algeria`
        }
      ]
    }
  },
  fr: {
    items: {
      firsts: [
        {
          title: `C’est à nos scientifiques des facultés des lettres et sciences humaines `,
          description: `de revoir les services à nos citoyens et principalement l’éducation de nos enfants.`
        },
        {
          title: `C’est à nos scientifiques des facultés de droits`,
          description: `d’instituer les règlements et politiques permettant un vivre ensemble citoyen.`
        },
        {
          title: `C’est à nos scientifiques des facultés d’administration`,
          description: `d’établir la rigueur administrative, avec en premier plan la systématisation des structures de contrôle, entre autre comptable.`
        },
        {
          title: `C’est à nos scientifiques des facultés de génie`,
          description: `de remettre sur pied l’infrastructure de production des biens.`
        }
      ],
      seconds: [
        {
          title: `C’est à nos scientifiques des facultés des sports`,
          description: `de former nos prochains champions olympiques.`
        },
        {
          title: `C’est à nos scientifiques des facultés des arts`,
          description: `de nous faire revivre notre culture multidimensionnelle.`
        },
        {
          title: `C’est à nos scientifiques des facultés de médecines`,
          description: `d’assurer que nos prochains présidents soient soignés dans nos hôpitaux.`
        },
        {
          title: `C’est à nos scientifiques des facultés des sciences`,
          description: `de nous amener vers l’Algérie 2.0.`
        },
        {
          title: `Sans oublier tous les autres scientifiques, techniciens, ingénieurs, chercheurs, professeurs, toutes et tous sans exclusions`,
          description: `doivent apporter pour une Algérie forte.`
        }
      ]
    }
  }
};
