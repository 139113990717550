import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Spin, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { contactSelector } from './selector';
import { submitMessage } from '../../store/reducers/Request/actions';
import './style.scss';
import { getEmail } from '../../store/reducers/settings/selector';

export function Contact() {
  const { t } = useTranslation();
  const initial = useRef({ email: '', name: '', message: '' });
  const { loading } = useSelector(contactSelector);
  const { email: localEmail } = useSelector(getEmail);

  const [formState, setFormState] = useState(initial.current);
  const onChange = useCallback(
    event =>
      setFormState({ ...formState, [event.target.name]: event.target.value }),
    [formState]
  );
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(submitMessage(formState, t));
    },
    [formState, dispatch, t]
  );
  useEffect(() => {
    setFormState({ ...initial.current, email: localEmail });
  }, [localEmail]);

  return (
    <section
      className='footer-contact-area section_padding_100 clearfix  py-5 '
      id='contact'
    >
      <div className='container-fluid py-2'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='section-heading my-4'>
              <h2 className='get-in-touch text-dark'>{t('contact:title')}</h2>
              <div className='line-shape d-none d-md-block'></div>
            </div>

            <div className='email-text text-title font-weight-bold'>
              <p>
                <span>Email:</span>{' '}
                <a
                  className='text-title email-link'
                  href='mailto:info@dzscience.com'
                >
                  info@dzscience.com
                </a>
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='contact_from'>
              <form onSubmit={onSubmit} method='post'>
                <div className='contact_input_area'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <input
                          className='form-control'
                          name='name'
                          value={formState.name}
                          disabled={loading}
                          id='name'
                          placeholder={t('contact:name')}
                          required
                          type='text'
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <input
                          type='email'
                          className='form-control'
                          value={formState.email}
                          disabled={loading}
                          name='email'
                          id='email'
                          placeholder={t('contact:email')}
                          required
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='form-group'>
                        <textarea
                          name='message'
                          disabled={loading}
                          value={formState.message}
                          className='form-control'
                          id='message'
                          cols='30'
                          rows='4'
                          placeholder={t('contact:message')}
                          onChange={onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-12'>
                      <button type='submit' className='btn send'>
                        {loading ? (
                          <Spin
                            indicator={
                              <Icon
                                type='loading'
                                style={{ fontSize: 30, color: 'white' }}
                              />
                            }
                          />
                        ) : (
                          t('contact:action') + ''
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
