import i18n from "i18next";
import LocalStorageBackend from "i18next-localstorage-backend"; // primary use cache
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import projects from "../../components/Projects/text";
import about from "../../components/About/text";
import bullets from "../../components/About/Bullets/text";
import header from "../../components/Header/text";
import footer from "../../components/Footer/text";
import home from "../../components/HomeBanner/text";
import organization from "../../components/Organization/text";
import contact from "../../components/Contact/text";
import common from "./common";

export default i18n
  .use(initReactI18next)
  .use(LocalStorageBackend)
  .use(LanguageDetector)
  .init({
    returnObjects: true,
    resources: {
      en: {
        common: common.en,
        projects: projects.en,
        about: about.en,
        bullets: bullets.en,
        header: header.en,
        footer: footer.en,
        home: home.en,
        organization: organization.en,
        contact: contact.en
      },
      fr: {
        common: common.fr,
        projects: projects.fr,
        about: about.fr,
        bullets: bullets.fr,
        header: header.fr,
        footer: footer.fr,
        home: home.fr,
        organization: organization.fr,
        contact: contact.fr
      }
    },
    fallbackLng: "en-US",
    react: {
      useSuspense: false
    }
  });
