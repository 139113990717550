import React, { Suspense } from 'react';
import './App.scss';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HomePage } from './pages/HomePage';
import { store } from './store';
import { Spin } from 'antd';
import './utils/language';
export default function App() {
  return (
    <Suspense fallback={<Spin />}>
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route path='/' exact component={HomePage} />
            <Redirect to='/' />
          </Switch>
        </HashRouter>
      </Provider>
    </Suspense>
  );
}
