import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

export function Organization() {
  const { t } = useTranslation();
  return (
    <>
      <section className="container about-section ">
        <div className="text-center position-relative">
          <h2 className="mt-5">{t("organization:title")}</h2>
          <img
            alt="title"
            className="title-container "
            src="/img/title-container.svg"
          />
        </div>
      </section>
      <section className="mission-section p-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 mb-3 mb-md-0">
              <div className="w-100 text-center mb-3">
                <img
                  alt="title"
                  src="/img/icons/mission.svg"
                  className="w-75 item-icon"
                />
              </div>
              <h4 className="text-white"> {t("organization:mission.title")}</h4>
              <p> {t("organization:mission.subTitle")} </p>
            </div>

            <div className="col-12 col-md-4 mb-3 mb-md-0">
              <div className="w-100 text-center mb-3">
                <img
                  alt="title"
                  src="/img/icons/organization.svg"
                  className="w-75 item-icon"
                />
              </div>
              <h4 className="text-white">
                {t("organization:governance.title")}
              </h4>
              <p className="text-justify">
                {t("organization:governance.subTitle")}
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="w-100 text-center mb-3">
                <img
                  alt="title"
                  src="/img/icons/operation.svg"
                  className="w-75 item-icon"
                />
              </div>

              <h4 className="text-white">
                {t("organization:operations.title")}
              </h4>
              <p className="text-center">
                {t("organization:operations.subTitle")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
