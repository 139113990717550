import React from "react";
import { HomeBanner } from "../components/HomeBanner/HomeBanner";
import { About } from "../components/About/About";
import { Organization } from "../components/Organization/Organization";
import { Projects } from "../components/Projects/Projects";
import { Footer } from "../components/Footer/Footer";
import ScrollUpButton from "react-scroll-up-button";

export function HomePage() {
  return (
    <>
      <HomeBanner />
      <About />
      <Organization />
      <Projects />
      <Footer />
      <ScrollUpButton
        ShowAtPosition={250}
        ContainerClassName="rounded"
        style={{
          width: "40px",
          height: "40px",
          zIndex: "9999999",
          background: "#f55f44",
          bottom: "0",
          padding: "8px"
        }}
      />
    </>
  );
}
