export default {
  en: {
    messages: {
      success: {
        title: "Thank you!",
        content: "Your message has been sent successfully."
      },
      fail: {
        title: "Sorry!",
        content: "Some Error Occurred,Please Try Again."
      }
    },
    interest: {
      success: {
        title: "Thank you!",
        content: "We appreciate your interest, we will contact you soon."
      },
      fail: {
        title: "Sorry!",
        content: "Some Error Occurred,Please Try Again."
      }
    }
  },
  fr: {
    messages: {
      success: {
        title: "Merci!",
        content: "Votre message a été envoyé avec succès."
      },
      fail: {
        title: "Pardon!",
        content: "Une erreur s'est produite, veuillez réessayer."
      }
    },
    interest: {
      success: {
        title: "Merci!",
        content:
          "Nous apprécions votre intérêt, nous vous contacterons bientôt."
      },
      fail: {
        title: "Pardon!",
        content: "Une erreur s'est produite, veuillez réessayer."
      }
    }
  }
};
