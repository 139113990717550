import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { Hierarchy } from './Hierarchy';
import { InterestedButton } from './InterestedButton';
import { func, string } from 'prop-types';

const { TabPane } = Tabs;

export function DZScience() {
  const { t } = useTranslation();
  return (
    <div className='project-item pt-4'>
      <div className='row justify-content-center '>
        <div className='col-10'>
          <div className='d-flex my-4'>
            <h3 className='text-title'>{t('projects:dzscience.title')}</h3>
          </div>
          <div className='d-md-none'>
            <ResponsiveTabs t={t} tabPosition='top' />
          </div>
          <div className='d-none d-md-block'>
            <ResponsiveTabs t={t} tabPosition='left' />
          </div>
        </div>
      </div>
    </div>
  );
}

const ResponsiveTabs = ({ t, tabPosition }) => (
  <Tabs defaultActiveKey='1' tabPosition={tabPosition} className='text-center '>
    <TabPane
      key='1'
      tab={
        <div className='mx-5'>{t('projects:dzscience.tabs.goal.title')}</div>
      }
    >
      <div className='text-left text-subtitle'>
        {t('projects:dzscience.tabs.goal.content')}
      </div>
    </TabPane>
    <TabPane
      key='2'
      tab={
        <div className='mx-5'>
          {t('projects:dzscience.tabs.description.title')}
        </div>
      }
    >
      <div className='text-left text-subtitle'>
        {t('projects:dzscience.tabs.description.start')}
        {<Hierarchy />}.
        <br />
        {t('projects:dzscience.tabs.description.the')}
        {<Hierarchy />}
        {t('projects:dzscience.tabs.description.theEnd')}
        <ul>
          {t('projects:dzscience.tabs.description.allow').map((item, index) => (
            <li className='my-1' key={index}>
              {item}
            </li>
          ))}
        </ul>
        {t('projects:dzscience.tabs.description.end')}
      </div>
    </TabPane>

    <TabPane
      key='3'
      tab={
        <div className='mx-5'>
          {t('projects:dzscience.tabs.advancement.title')}
        </div>
      }
    >
      <div className='text-left text-subtitle'>
        {t('projects:dzscience.tabs.advancement.content')}
      </div>
    </TabPane>
    <TabPane
      key='4'
      tab={
        <div className='mx-5'>
          {t('projects:dzscience.tabs.interested.title')}
        </div>
      }
    >
      <div className='text-left'>
        {t('projects:dzscience.tabs.interested.content')}
      </div>
      <InterestedButton project='dzscience' />
    </TabPane>
  </Tabs>
);
ResponsiveTabs.propTypes = {
  t: func.isRequired,
  tabPosition: string.isRequired
};
