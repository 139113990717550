export default {
  en: {
    presentation: `We want to help the advancement of science in Algeria. Participate.`,
    start: "Start",
    title: "The crossroads of Algerian scientists"
  },
  fr: {
    presentation: `Nous voulons aider à l’avancement de la science en Algérie. Participez.`,
    title: "Le carrefour des scientifiques algériens",
    start: "Commencer"
  }
};
