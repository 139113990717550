import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

const { Option } = Select;

export function LanguagePicker({ languages }) {
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    lang => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );
  let language = languages.find(l => l.code === i18n.language);
  language = ((language && language.code) || "en").substring(0, 2);

  return (
    <div className="dropdown show">
      <Select
        className="lang-picker"
        value={language}
        style={{ width: "140px" }}
        onChange={changeLanguage}
        dropdownStyle={{ zIndex: "9999999" }}
      >
        {languages.map(({ code, name }) => (
          <Option value={code} key={code}>
            <img
              alt="language flag"
              className="mr-2"
              style={{ height: "20px" }}
              src={`/img/icons/${code}.png`}
            ></img>
            {name}
          </Option>
        ))}
      </Select>
    </div>
  );
}

LanguagePicker.propTypes = {
  languages: PropTypes.array.isRequired
};

LanguagePicker.defaultProps = {
  languages: [
    { code: "en", name: "English" },
    { code: "fr", name: "Francais" }
  ]
};
