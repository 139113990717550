export default {
  en: {
    title: "Organization",
    mission: {
      title: "Mission",
      subTitle: "Helping advance science in Algeria."
    },
    governance: {
      title: "Mode of governance",
      subTitle:
        "Whether in Algeria or abroad, DZScience will be administered by a non-profit organization (NPO). The creation procedures have already been initiated."
    },
    operations: {
      title: "Mode of operation",
      subTitle: "DZScience will operate in projects."
    }
  },
  fr: {
    title: "Organisation",
    mission: {
      title: "Mission",
      subTitle: "Aider à faire avancer la science en Algérie."
    },
    governance: {
      title: "Gouvernance",
      subTitle: `Que ce soit en Algérie ou à l’étranger, DZScience va être administrée par une organisation à but non lucratif (OBNL). Les démarches de création ont été déjà initiées.`
    },
    operations: {
      title: "Opérations",
      subTitle: "DZScience va opérer en projets."
    }
  }
};
